.text{
    margin-bottom: 10px;
}

.title{
    margin-top: 20px;
    margin-bottom: 6px;
}

.caption_error{
    /* text-align: left;  */
    color: var(--text_subhead);
    font-size: 12px;
    margin-bottom: 15px;
}

.list_item::before{
    content: '- ';
}
.list_item{
    margin-bottom: 10px;
}