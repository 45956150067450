#third.mainDiv{
    color: white;
    position: absolute; 
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    left:0;
    right:0;
    top:0;
    bottom:0;
    background: linear-gradient(#7EA2FF 0%, rgba(132, 167, 255, 0.953125) 18.23%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
    background-color: rgb(12, 21, 59);
    animation-name: background_color_3;
    animation-duration: 2s; 
    animation-fill-mode: forwards;
}

.description_3{
    text-align: center;
    margin-top: 50px;
    /* visibility: hidden; */
    animation-fill-mode: forwards;
    animation-name: show_element;
    animation-duration: 2s; 
    visibility: hidden;
}

.buttons_3{
    animation-fill-mode: forwards;
    animation-name: show_element;
    animation-duration: 2s; 
    visibility: hidden;
}

.element_for_hide_3{
    color: white;
    position: absolute; 
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    left:0;
    right:0;
    top:0;
    bottom:0;
    background: linear-gradient(#7EA2FF 0%, rgba(132, 167, 255, 0.953125) 18.23%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
    background-color: #912626;
    animation-name: hide_element;
    animation-duration: 0.2s; 
    /* animation-fill-mode: forwards; */

}

@keyframes background_color_3 {
    0%   {background-color: rgb(12, 21, 59);}
    100% {background-color: #912626;}
  }

@keyframes show_element {
    0%   { opacity: 0;
        visibility: visible; }
    100% { opacity: 1;
    visibility: visible; }
}

@keyframes hide_element {
    /* 0%   {opacity: 0%;} */
    100%   {
        transform: translateY(10px);
        /* transform: scale(sx[, sy]);
        transform: translateY(10px); */
        opacity: 10%;
    }
}