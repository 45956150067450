.sym_container{
    padding: 10px;
    border: 1px solid var(--button_primary_background);
    border-radius: 10px;
    margin: 10px;
    text-align: center;
}

.sym_div{
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(80px, 1fr));
}

.sym_div_big{
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(120px, 1fr));
}