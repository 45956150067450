.doneIcon{
    padding-top: 35px;
    padding-right: 16px;
    margin: 0;
}

.cardDiv {
    display: flex;
    align-items: center;
    justify-content: center;
  }

.cardTitle {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding-left: 16px;
    margin: 0;
  
  }


.caption_group{
    font-size: 14px;
    color: var(--text_subhead);
    margin-left: 10px;
}
  
.caption{
    text-align: center;
    font-size: 14px;
    color: var(--text_subhead);
    /* padding-bottom: 0;
    margin: 0;
    padding: 0; */
  }

.style_scroll_card{
    margin-bottom: 20px;
    cursor: grab;
}

.style_scroll_card .Card__in, .style_scroll_card .Card__in .cardDiv  {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.flag{
    width: 30px;
    height: 30px;
}


.MoreStylesDiv {
  display: flex;
  justify-content: center;
  margin: 0;
}

.example_text{
    text-align: center;
    margin: 0;
    font-size: 20px;
}

.arrow{
  left: 30px;
  /* width: 63px; */
  position: absolute;
  z-index:99999999; 
    top: 30%;
    width: calc(var(--swiper-navigation-size)/ 44 * 27);
    height: var(--swiper-navigation-size);
    margin-top: calc(-1 * var(--swiper-navigation-size)/ 2);
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--swiper-navigation-color,var(--swiper-theme-color));
}