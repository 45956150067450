
.CardDiv {
  display: flex;
  justify-content: space-between;
}

.ToolsCardTitle {
  padding-top: 16px;
  padding-left: 16px;
  margin: 0;

}

.CardIcon {
  align-self: center;
  padding-right: 35px;
}
