.box{
    height: 100vh;
    background: linear-gradient(180deg, #7EA2FF 0%, rgba(132, 167, 255, 0.953125) 18.23%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
    background-position:40px 40px;
  }

.caption{
  text-align: center;
  font-size: 14px;
  color: var(--text_subhead);
}

.plug_text-conventer{
  text-align: center;
  color: var(--text_subhead);

}

.plug-icon{
  color: var(--text_subhead);
  margin-left: auto;
  margin-right: auto;
  opacity: 0.3;
}

.result_card {
  font-size: 15px;
  text-align: center;
  height: auto;
  word-wrap: break-word;
  overflow: hidden;
}

.no-select {
  user-select: none;
}

.no-wrap{
  white-space: nowrap;
}

.no_padding_top{
  padding-top: 0px;
}



/* /* .TextCardScroll#1 .HorizontalScroll .HorizontalScroll_in .CardScroll_in:nth-child(-n + 3) { 
  font-size: 32px;
} */

/* .TextCardScroll

.example-text {
  font-size: 20px;
  /* display: contents;
  display: inline-block;
  margin: 0;
} */ 